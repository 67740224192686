<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import AlertWidget from "@/components/general/AlertWidget";
import Swal from "sweetalert2";
/**
 * Add-product component
 */
export default {
  page: {
    title: "Shifts Records",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  props : {
    shifts : {
      type: [Object, Array],
      default: () => {
          return []
      }
    },
    next_page_url : {
      tyoe : String
    }
  },
  components: {
   AlertWidget
  },
  data() {
    return {
      arearole:null,
      shiftsCopy:[],
      shift_timeline : {}
    };
  },
  middleware: "authentication",
  mounted(){
    this.shiftsCopy = this.shifts;
  },
  watch:{
    shifts: {
      handler(newVal,oldVal)
      {
        this.shiftsCopy = newVal;
      },
      immediate: true,
      deep : true,
    }
  },
  methods:{
    submitActions(timeline){
      let self =this;
      self.$axios.post('shift/employee/shift-action/'+timeline.id, {
        status:"ignored" ,
        employee_id:this.currentUser.employee.id,
        area_role_id:timeline.area_role_id,
        shift_id:timeline.shift_id
      })
      .then(response => {
          let data = response.data;
          this.shiftsCopy= this.shiftsCopy.filter((shift)=> (timeline.id!=shift.id))
          self.triggerSwal(data.message, "success");
                            
      })
      .catch(error => {
          self.handleErrorResponse(error.response, "error");
          
      })
      .finally(function () {
          
      });
    },

    confirmOverTimeShift(shift_timeline) {
      let message =  this.currentUser.employee.minor == 1 ? "requested to schedule" : "scheduled" ;
      Swal.fire({
        title: "Are you sure?",
        text:  "You have already " +message+ " a 40-hour shift for this week, if you need to work an overtime shift, your request will be forwarded to management for approval.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70C213",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, Accept",
      }).then((result) => {
        if (result.value) {
            this.selectShift(shift_timeline);
        }
      });
    },

    checkCounts(timeline)
    {
      this.shift_timeline = {...timeline};
      this.$axios
      .get("counts-stats/index", {
        params: {employee_id :this.currentUser.employee.id, shift_id:  timeline.shift_id }
      })
      .then((response) => {
        let totalHours = response.data.payload;
        if(totalHours > 40)
        {
          this.confirmOverTimeShift(this.shift_timeline);
        }else{
          this.selectShift(this.shift_timeline);
        }
      }).catch(error => {
        this.handleErrorResponse(error.response, "error");
      });
    },

    selectShift(timeline) {
      this.submit = true;
      this.eventModal = false;
      let self = this;
      
      self.$axios.post('shift/employee/select-shift', {
        employee_id:this.currentUser.employee.id,
        shift_id:timeline.shift_id, 
        area_role_id:timeline.area_role_id,
        from:timeline.from,
        to:timeline.to,
        status:'Pending',
        timeline_id:timeline.id,
        pick_up_released:true,
        slot_id:timeline.slot_id,
      })
      .then(response => {
          let data = response.data;
          self.triggerSwal(data.message, "success");
          // location.reload();
          this.shiftsCopy= this.shiftsCopy.filter((shift)=> (timeline.id!=shift.id))
      })
      .catch(error => {
        self.handleErrorResponse(error.response, "error");
      })
      .finally(() => {

      });
    },

  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card dashboard__wrapper">
          <div class="card-header bg-white border-bottom p-3 border-top-radius">
                <h2 class="f-20 poppins text-dark mb-0">Pick-Up Released Shifts</h2>
              </div>
          <div class="card-body">
          
            <div class="table-responsive">
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                  
                    <th class="f-14 roboto">Shift Name</th>
                    <th class="f-14 roboto">Role/Position</th>
                    <th class="f-14 roboto">Shift From</th>
                    <th class="f-14 roboto">Shift To</th>
                    <th class="f-14 roboto">Location</th>
                    <!-- <th class="f-14 roboto">Selected Time From</th>
                    <th class="f-14 roboto">Selected Time To</th> -->
                    <th class="f-14 roboto">Action</th>
             
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(shift,index) in shiftsCopy" :key="index">
                    <!-- <template v-if="shift.status=='Released'"> -->
                    
                    <td class="f-14 roboto align-middle">{{getSafe(() =>  shift.shift.name)}}</td>
                    <td class="f-14 roboto align-middle">{{getSafe(() => shift.area_role.name)}}</td>
                    <td class="f-14 roboto align-middle">
                     {{getSafe(() => DateWithMonthName(shift.from))}}
                    </td>
                    <td class="f-14 roboto align-middle">
                      {{getSafe(() => DateWithMonthName(shift.to))}}
                    </td>
                    <td class="f-14 roboto align-middle">
                      {{getSafe(() => shift.shift.location.name)}}
                    </td>
                    <!-- <td class="f-14 roboto align-middle">
                     {{DateWithMonthName(shift.shift.from)}}
                    </td>
                    <td class="f-14 roboto align-middle">
                      {{DateWithMonthName(shift.shift.to)}}
                    </td> -->
                    <td class="f-14 roboto align-middle">
                             <div
                       
                        class="action-buttons d-flex gap-2 justify-content-between"
                      >
                        <button
                          class="btn-outline-success btn py-1"
                          @click="checkCounts(shift)"
                         
                          v-b-tooltip.hover
                          title="Accept"
                          v-b-modal.modal-reason

                        >
                          <i class="fas fa-check-circle"></i>
                        </button>
                        <button
                         
                          class="btn-outline-danger btn py-1"
                          @click="submitActions(shift)"
                          v-b-tooltip.hover
                          title="Reject"
                          v-b-modal.modal-reason
                        >
                          <i class="fas fa-times-circle"></i>
                        </button>
                      </div>

                    </td>
                    <!-- </template> -->
                  </tr>
                  <tr v-if="shiftsCopy.length <= 0 ">
                    <td colspan="8">
                      <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="seeMore pt-3 text-center" v-if="next_page_url">
                <a href="javascript:void(0)" @click="$emit('seeMoreReleasedShifts');">See More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end .border-->
  </div>
</template>
