<script>
import Layout from "@/views/pages/employee/layout/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import pickReleasedShiftVue from "../components/pickReleasedShift.vue";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";


/**
 * Add-product component
 */
export default {
  page: {
    title: "Schedule",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    pickReleasedShiftVue,
  },
  
  data() {
    return {
      title: "Schedule",
      processing:false,
      items: [
        {
          text: "Schedule",
        },
        {
          text: "Schedule",
          active: true,
        },
      ],
      shifts:[],
  
    };
  },
  mounted(){
    this.fetchShift();
  },

    methods: {
     fetchShift() {
                this.$axios.get("shift-timeline/edit", {params:{shift_id:this.$route.params.shift_id}}).then((response) => {
                let data = response.data.payload;
                this.shifts=data;
                }).catch(error => {
                this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });
            },

    submitShift(e) {
      this.submit = true;
     
      this.eventModal = false;

                  let self = this;
            if (self.processing) {
                return;
            }
            self.processing = true;
            
            self.$axios.post('shift/employee/select-shift', {
                employee_id:this.currentUser.employee.id,
                shift_id:this.editevent.shift.id, 
                area_role_id:this.area_role_id,
                status:'Released',
          })
                .then(response => {
                    let data = response.data;

                    self.triggerSwal(data.message, "success");
                  self.$router.push('/employee-home');
                                     
                })
                .catch(error => {
                    self.handleErrorResponse(error.response, "error");
                    
                })
                .finally(function () {
                    self.processing = false;
                });
    },

    
  },

    validations: {
    event: {
      title: { required },
      category: { required },
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <pickReleasedShiftVue :shifts="shifts"></pickReleasedShiftVue>

  </Layout>
</template>
